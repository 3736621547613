
import { Call } from "./Call";
import { Device } from "./Device";
import { EventEmitter } from "./EventEmitter";
import * as anCti from "./ancti";

export class Agent implements anCti.Agent {

	static window: any = typeof window == 'undefined' ? {} : window;
	static scriptSrc = Agent.window?.document?.currentScript?.getAttribute("src");

	protected invokeId: number = 0;
	protected invokeIdPrefix = ''; // allows to identify invokeId-originators for debugging
	protected ws: any;
	protected connected: boolean;
	protected reconnecting: boolean;
	protected url?: string | null;
	protected username: string;
	protected password: string;
	protected accessKey?: string;
	protected sessionDuration: number;
	protected sessionID?: string;
	protected sessionDevice?: string;
	protected applicationID: string = "ancti";
	protected authentication: string = "digest";
	protected requestedSessionDuration?: number;
	protected clickToCall?: anCti.ClickToCallOptions;
	protected guest?: anCti.ApplicationSessionGuestOptions;
	protected profile?: string;
	protected processRtcEvents: boolean = true;

	protected invocations = {};
	protected queue: any[] = [];
	protected reconnectDelay = 5000;
	protected keepaliveInterval = 0;
	protected reconnectTimeout: any;
	protected invocationTimeout = 0;

	protected devices: Device[] = [];
	protected monitors = {};
	protected eventEmitter = new EventEmitter();

	config?: any = {};
	token?: string | boolean;
	audioDeviceId?: string;
	videoDeviceId?: string;

	RTCSessionDescription: any;
	RTCPeerConnection: any;
	MediaStream: any;
	mediaDevices: any;
	userAgent?: string;

	error = console.error.bind(console);
	warn = console.warn.bind(console);
	info = console.info.bind(console);
	debug = console.debug.bind(console);

	constructor() {
		this.RTCSessionDescription = Agent.window?.RTCSessionDescription;
		this.RTCPeerConnection = Agent.window?.RTCPeerConnection;
		this.mediaDevices = Agent.window?.navigator?.mediaDevices;
		this.MediaStream = Agent.window?.MediaStream;

		// per default take the same url as the library was loaded from
		this.url = Agent.scriptSrc;
		if (!this.url?.startsWith("http") && Agent.window?.location) {
			if (this.url?.startsWith("/")) {
				this.url = Agent.window.location.origin + this.url;
			} else {
				this.url = Agent.window.location.href;
			}
		}
		if (this.url) {
			this.url = this.url.replace(/^http/, "ws")
			//this.url = this.url.replace(/\/([^/]+)$/,"/ws")
			this.url = this.url.replace(/\/cti\/.*$/, "/cti/ws");
			this.debug("url", this.url);
		}
	}

	notify(eventName: string, event: any = {}) {
		this.debug("notify", eventName, event);
		this.eventEmitter.notify(eventName, event);
	}

	getMediaDeviceId(media: string): string {
		if (media == "audio") return this.audioDeviceId;
		if (media == "video") return this.videoDeviceId;
		return undefined;
	}

	getUserMedia(call: Call | null, constraints?: MediaStreamConstraints): Promise<MediaStream> {
		return this.mediaDevices.getUserMedia(constraints);
	}

	getDisplayMedia(call: Call | null, constraints?: anCti.GetDisplayMediaConstraints): Promise<MediaStream> {
		return this.mediaDevices.getDisplayMedia(constraints);
	}

	public on(eventName: string, fn: (event: any) => void) {
		this.eventEmitter.on(eventName, fn);
	}

	urlParam(name: string): string | null {
		return new URL(Agent.window?.location.href).searchParams.get(name);
	}

	getDevice(deviceID: string): Device {
		// map "pbx."" domains to "ou." 
		deviceID = deviceID?.replace(/(.*)@pbx\.(\d+)$/, "$1@ou.$2");
		let device = this.devices.find(d => d.deviceID == deviceID);
		if (!device) {
			device = new Device(this, deviceID)
			this.devices.push(device)
		}
		return device;
	}

	getDevices(): Device[] {
		return this.devices;
	}

	getSessionDevice(): Device | undefined {
		return this.sessionDevice && this.getDevice(this.sessionDevice);
	}

	registerMonitor(deviceID: string, monitorCrossRefID: string): Device {
		let device = this.getDevice(deviceID);
		this.monitors[monitorCrossRefID] = device;
		return device;
	}

	setAudioDeviceId(deviceId: string) {
		this.audioDeviceId = deviceId;
		this.debug("audioDeviceId is", deviceId);
	}

	setVideoDeviceId(deviceId: string) {
		this.videoDeviceId = deviceId;
		this.debug("videoDeviceId is", deviceId);
	}

	send(message: any) {
		this.removeUndefinedMembers(message);
		if (!this.connected) {
			this.queue.push(message);
		} else {
			this.info("send:", message);
			let text = JSON.stringify(message);
			try {
				this.ws?.send(text);
			} catch (error) {
				this.error("websocket cannot send", error);
			}
		}
	}

	async invoke(message: any): Promise<any> {
		let invId = `${this.invokeIdPrefix}${++this.invokeId}`;
		let promise = new Promise((resolve, reject) => this.invocations[invId] = { resolve: resolve, reject: reject });
		let data: any = Object.values(message)[0];
		data.invokeID = invId;
		if (this.invocationTimeout > 0) {
			setTimeout(() => {
				const invocation = this.invocations[invId];
				if (invocation) {
					invocation.reject("timeout");
					this.ws?.close();
				}
			}, this.invocationTimeout * 1000);
		}
		this.send(message);
		return promise;
	}

	protected startKeepalive() {
		if (this.keepaliveInterval > 0) {
			setTimeout(() => {
				if (this.connected) {
					this.debug("sending keepalive");
					this.ws.send("");
					this.startKeepalive();
				}
			}, this.keepaliveInterval * 1000);
		}
	}

	protected onSocketOpen() {
		this.connected = true;
		this.startKeepalive();
		this.sendStartApplicationSession();
	}

	protected onSocketError(error: any) {
		this.info('socket error:', error);
		this.disconnect();
		this.processApplicationSessionTerminated("error");
	}

	protected onSocketClose(error: any) {
		this.info('socket closed:', error);
		this.disconnect();
		this.processApplicationSessionTerminated("error");
	}

	protected onSocketMessage(event: any) {
		try {
			let message = JSON.parse(event.data);
			this.info("recv:", message);
			this.processMessage(message);
		} catch (error) {
			this.error("cannot process message", error);
		}
	}

	processMessage(message: any) {
		let name = Object.keys(message)[0];
		let content = message[name] || {};

		let device: Device = this.monitors[content.monitorCrossRefID];
		let call: Call | undefined;
		let invId = content.invokeID;
		let invocation = invId ? this.invocations[invId] : undefined;

		switch (name) {
			// Events
			case 'ServiceInitiatedEvent':
				call = device.getCall(content.initiatedConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'FailedEvent':
				call = device?.getCall(content.failedConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'ConnectionClearedEvent':
				call = device?.getCall(content.droppedConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'OriginatedEvent':
				call = device?.getCall(content.originatedConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'DeliveredEvent':
			case 'DivertedEvent':
				call = device?.getCall(content.connection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'EstablishedEvent':
				call = device?.getCall(content.establishedConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'HeldEvent':
				call = device?.getCall(content.heldConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'RetrievedEvent':
				call = device?.getCall(content.retrievedConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'TransferedEvent': // ecma style...
			case 'TransferredEvent':
				// Don't create call, otherwise we might get duplicated
				// call-instances if an TransferedEvent is emitted twice.
				call = device?.getCall(content.primaryOldCall.callID);
				call?.processEvent(name, content);
				if (content.localConnectionInfo == "null") {
					// secondary old call is gone as well
					call = device.getCall(content.secondaryOldCall?.callID);
					call?.processEvent(name, content);
				}
				break;
			case 'ConferencedEvent':
				call = device?.getCall(content.primaryOldCall.callID, true);
				call?.processEvent(name, content);
				break;
			case 'DtmfDetectedEvent':
				call = device?.getCall(content.overConnection.callID, true);
				call?.processEvent(name, content);
				break;
			case 'StopEvent':
				device?.notify("call", {
					// note that call might be gone already
					name: name,
					content: content,
					call: device.getCall(content.connection.callID)
				});
				break;
			case 'DoNotDisturbEvent':
				device?.processDoNotDisturb(content);
				break;
			case 'ForwardEvent':
				device?.processForward(content);
				break;

			// Proprietary events
			case 'RtcEvent':
				call = device?.getCall(content.connection.callID, true);
				if (this.processRtcEvents) {
					call?.processRtcEvent(content);
				}
				break;
			case 'GenerateDigitsEvent':
				call = device?.getCall(content.connectionToSendDigits.callID, true);
				call?.processGenerateDigits(content.charactersToSend, content.toneDuration);
				break;
			case 'MessageSummaryEvent':
				device?.processMessageSummary(content);
				break;
			case 'ActivityEvent':
				device?.processActivity(content);
				break;
			case 'ChatGroupEvent':
				device?.processChatGroup(content);
				break;
			case 'ChatGroupDeletedEvent':
				device?.processChatGroupDeleted(content);
				break;
			case 'ConfigNotificationEvent':
				device?.processConfigNotification(content.data);
				break;

			case 'ConferenceUpdateEvent':
				call = device?.getCall(content.connection?.callID, false);
				if (call?.pc) {
					// ignore the ConferenceUpdateEvent if we have no peer webRTC connection 
					// in that case we just passively observe the conference, but we aren't involved in the media
					this.notify("conferenceupdate", { name, call, content });
				}
				break;
			case 'ConferenceStream':
				call = device?.getCall(content.connection?.callID, false);
				if (call?.pc) {
					// ignore the ConferenceUpdateEvent if we have no peer webRTC connection 
					// in that case we just passively observe the conference, but we aren't involved in the media
					this.notify("conferencestream", { name, call, content });
				}
				break;

			// events outside of a monitor
			case 'PresenceStateEvent':
				console.log("PresenceStateEvent", content);
				device = this.devices.find(dev => dev.deviceID == content.device);
				device?.processPresenceState(content);
				break;
			case 'ApplicationSessionTerminated':
				this.processApplicationSessionTerminated(content?.sessionTermReason?.definedTermReason);
				break;

			case 'CSTAErrorCode':
				invocation?.reject(content);
				break;

			default:
				if (invocation) {
					invocation.resolve(content);
				} else {
					this.info("unknown message:", name);
				}
		}

		if (invocation) {
			delete this.invocations[content.invokeID];
		} else {
			this.notify("event", {
				deviceID: device?.deviceID,
				monitorCrossRefID: content?.monitorCrossRefID,
				callID: call?.callID,
				name,
				message,
			});
		}
	}

	protected reconnect() {
		this.disconnect();
		this.debug('reconnecting to', this.url);
		try {
			if (this.url) {
				this.ws = new WebSocket(this.url);
				this.ws.onopen = () => this.onSocketOpen();
				this.ws.onerror = (e: any) => this.onSocketError(e);
				this.ws.onclose = (e: any) => this.onSocketClose(e);
				this.ws.onmessage = (e: any) => this.onSocketMessage(e);
			}
		} catch (error) {
			this.error("could not setup socket", error);
		}
		this.startReconnectTimeout();
	}

	protected startReconnectTimeout(delay: number = this.reconnectDelay) {
		try {
			this.info(`reconnecting in ${delay} milliseconds`);
			this.reconnectTimeout && clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = setTimeout(() => {
				if (this.reconnecting && !this.connected) {
					this.reconnect();
				}
			}, delay);
		} catch (error) {
			this.error("cannot start reconnect timeout", error);
		}
	}

	protected disconnect() {
		this.connected = false;
		if (this.ws) {
			try {
				this.ws.onopen = undefined;
				this.ws.onerror = undefined;
				this.ws.onclose = undefined;
				this.ws.onmessage = undefined;
				this.debug("before closing websocket");
				this.ws.close();
				this.debug("after closing websocket");
			} catch (error) {
				this.error("websocket cannot close", error);
			}
			this.debug("disconnected websocket");
		}
		this.ws = undefined;
	}

	startApplicationSession(options: anCti.AgentStartApplicationSessionOptions = { token: true }) {
		this.url = options.url || this.url;
		this.username = options.username || this.username;
		this.password = options.password || this.password;
		this.authentication = options.authentication || this.authentication;
		this.keepaliveInterval = options.keepaliveInterval || 0;
		this.invocationTimeout = options.invocationTimeout || 0;
		this.token = options.token || this.token;
		this.profile = options.profile || this.profile;
		this.accessKey = options.accessKey || this.accessKey;
		this.applicationID = options.applicationID || this.applicationID;
		this.userAgent = options["userAgent"] || this.userAgent;
		this.sessionDevice = options.sessionDevice || this.sessionDevice;
		this.guest = options.guest;
		this.requestedSessionDuration = options.requestedSessionDuration;
		this.reconnecting = true;

		if (options.clickToCall) {
			if (typeof options.clickToCall === "string") {
				this.clickToCall = { deviceID: options.clickToCall, audio: true };
			} else {
				this.clickToCall = options.clickToCall;
			}
		}
		if (this.clickToCall || options.reconnect === false) {
			this.reconnecting = false;
		}

		if (this.token === true) {
			// for backward  compatibility, token:true is converted to cookie:"ANAUTH"
			options.cookie = "ANAUTH";
		}

		if (options.cookie) {
			const re = new RegExp(`${options.cookie}=([^;]+)`);
			this.token = Agent.window?.document?.cookie?.match(re)?.[1];
		}

		if (!Agent.window?.TextEncoder || !Agent.window?.crypto?.subtle?.digest) {
			// digest only available on https pages
			this.authentication = "basic";
		}

		if (!this.connected) {
			this.reconnect();
		} else {
			this.sendStartApplicationSession();
		}
	}

	sendStartApplicationSession() {
		this.invoke({
			StartApplicationSession: {
				applicationInfo: {
					applicationID: this.applicationID,
					applicationSpecificInfo: {
						username: this.username,
						password: this.authentication == "basic" ? this.password : undefined,
						token: this.token,
						accessKey: this.accessKey,
						userAgent: this.getUserAgent(),
						config: true,
						clickToCall: this.clickToCall,
						guest: this.guest,
						profile: this.profile,
						sessionDevice: this.sessionDevice,
					}
				}
			}
		}).then((response: any) => {
			if (response.extensions?.nonce && this.username && this.password && this.authentication == "digest") {
				const msgUint8 = new TextEncoder().encode(this.username + ":" + this.password + ":" + response.extensions.nonce); // encode as (utf-8) Uint8Array
				crypto.subtle.digest('SHA-256', msgUint8).then(hashBuffer => {
					const hashArray = Array.from(new Uint8Array(hashBuffer));
					const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
					this.invoke({
						StartApplicationSession: {
							applicationInfo: {
								applicationID: this.applicationID,
								applicationSpecificInfo: {
									username: this.username,
									response: hashHex,
									userAgent: this.getUserAgent(),
									config: true,
									profile: this.profile,
									sessionDevice: this.sessionDevice,
								}
							},
							requestedSessionDuration: this.requestedSessionDuration
						}
					}).then((response2: any) => {
						this.processStartApplicationSessionResponse(response2);
					});
				});
			} else {
				this.processStartApplicationSessionResponse(response);
			}
		});
	}

	private processStartApplicationSessionResponse(response: any) {
		if (response.errorCode) {
			this.processApplicationSessionTerminated(response.errorCode);
		} else {
			this.sessionID = response.sessionID;
			this.sessionDuration = response.actualSessionDuration;
			Object.assign(this.config, response.config);

			// automatically initialise devices with received config
			this.config.deviceList?.forEach((cfg: any) => {
				const device: any = this.getDevice(cfg.deviceID);
				device.autoAnswer = cfg.autoAnswer;
				device.type = cfg.type;
				device.name = cfg.name;
				device.publicNumber = cfg.publicNumber;
				device.number = cfg.number;
				device.terminal = cfg.terminal;
				device.rtc = cfg.type == "cti";
			});

			this.notify("applicationsessionstarted", this.config);
			while (this.queue.length) {
				this.send(this.queue.shift());
			}

			if (response.clickToCall) {
				// the session automatically monitors the clickToCall device
				this.monitors[response.monitorCrossRefID] = this.getDevice(response.clickToCall.deviceID);
			} else if (response.config.guest) {
				this.monitors[response.monitorCrossRefID] = this.getDevice(response.config.guest.deviceID);
			}
		}
	}

	private processApplicationSessionTerminated(reason: string) {
		if (reason == 'shutdown' || reason == 'normal' || reason == 'invalidApplicationInfo' || this.clickToCall) {
			this.reconnecting = false;
		}
		if (!this.reconnecting) {
			this.sessionID = undefined;
			this.disconnect();
			this.config = {};
			this.devices.forEach(device => device.calls.forEach(call => call.shutdown()));
		}
		this.eventEmitter.notify("applicationsessionterminated", {
			reason: reason,
			reconnecting: this.reconnecting,
		});

		if (this.reconnecting) {
			this.startReconnectTimeout();
		}
	}

	stopApplicationSession() {
		this.invoke({
			StopApplicationSession: {
				sessionID: this.sessionID,
				sessionEndReason: {
					appEndReason: "normal"
				}
			}
		}).then(() => {
			this.reconnecting = false;
			this.disconnect();
		}).catch(error => {
			this.error("could not stop application-session", error);
		});
		this.processApplicationSessionTerminated("normal");

	}

	restartApplicationSession(delayMillis?: number) {
		this.disconnect();
		this.eventEmitter.notify("applicationsessionterminated", {
			reason: "restart",
			reconnecting: this.reconnecting,
		});
		this.startReconnectTimeout(delayMillis);
	}

	resetApplicationSessionTimer(requestedSessionDuration: number) {
		this.invoke({
			ResetApplicationSessionTimer: {
				requestedSessionDuration: requestedSessionDuration
			}
		}).then((response: any) => {
			this.sessionDuration = response.actualSessionDuration;
		});
	}

	async readDirectories(options: anCti.ReadDirectoriesOptions): Promise<anCti.ReadDirectoriesResponse> {
		let response: any = await this.invoke({
			ReadDirectories: {
				text: options.text,
				limit: options.limit,
				scope: options.scope,
				match: options.match,
				avatars: options.avatars,
				deviceIds: options.deviceIds,
				types: options.types,
			}
		});
		return response.entries;
	}

	// Utility functions
	parseDeviceID(deviceID: any, target: any = {}) {
		if (deviceID) {
			if (deviceID.hasOwnProperty("deviceIdentifier")) {
				deviceID = deviceID.deviceIdentifier;
			}
			if (deviceID) {
				let match = deviceID.match(/sip:([^@]*)/);
				if (match) {
					target.number = match[1]
					target.name = ""
				} else {
					match = deviceID.match(/N<([^>]*)>(.*)/);
					if (match) {
						target.number = match[1]
						target.name = match[2]
					}
				}
			}
		}
		return target
	}

	getMember(obj: any, ...path: string[]): any {
		for (const name of path) {
			obj[name] = obj[name] || {};
			obj = obj[name];
		}
		return obj;
	}

	private removeUndefinedMembers(obj: any) {
		try {
			Object.keys(obj).forEach((key) => {
				let val = obj[key];
				if (val === undefined || val == null) {
					delete obj[key];
				} else if (typeof val === 'object') {
					if (Object.keys(val).length == 0) {
						// RTCSessionDescription and others have no keys, just symbols. Just delete if there are no symbols.
						if (Object.getOwnPropertySymbols(Object.getPrototypeOf(val)).length == 0) {
							delete obj[key];
						}
					} else {
						this.removeUndefinedMembers(val);
					}
				}
			});
		} catch (error) {
			this.error("cannot remove undefined members", error);
		}
	}

	getUserAgent() {
		if (!this.userAgent) {
			this.userAgent = this.detectBrowser();
		}
		return this.userAgent;
	}

	private detectBrowser(): string {
		// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
		if (Agent.window?.navigator?.userAgent) {
			let ua = navigator.userAgent;
			let chrome = /Chrome\/([0-9.]+)/.exec(ua)?.[1];
			let firefox = /Firefox\/([0-9.]+)/.exec(ua)?.[1];
			let seamonkey = /Seamonkey\/([0-9.]+)/.exec(ua)?.[1];
			let chromium = /Chromium\/([0-9.]+)/.exec(ua)?.[1];
			let safari = /Safari\/([0-9.]+)/.exec(ua)?.[1];
			let opera = /OPR\/([0-9.]+)/.exec(ua)?.[1];

			if (firefox && !seamonkey) return `firefox/${firefox}`;
			if (seamonkey) return `seamonkey/${seamonkey}`;
			if (chrome && !chromium) return `chrome/${chrome}`;
			if (chromium) return `chromium/${chromium}`;
			if (safari && !chrome && !chromium) return `safari/${safari}`;
			if (opera) return `opera/${opera}`;
		}
		return "unknown";
	}

	async requestSystemStatus(): Promise<anCti.RequestSystemStatusResponse> {
		return this.invoke({
			RequestSystemStatus: {
			}
		});
	}

}

export function newAgent() {
	return new Agent();
}
