

interface Listener {
	off(): void;
}

export class EventEmitter {

	protected listeners: Record<string, Function[]> = {};
	protected parent?: EventEmitter;

	constructor(parent?: EventEmitter) {
		this.parent = parent;
	}

  	on(names: string, cb: Function): Listener {
		names.split(/ +/).forEach(name => (this.listeners[name] || (this.listeners[name] = [])).push(cb));

		return {
      		off: () => names.split(/ +/).forEach(name => this.listeners[name].filter(l => l!==cb))
    	};
  	}

	notify(eventName: string, data: any = {}) {
		let event = {
			type: eventName,
			defaultPrevented: false,
			preventDefault: () => { event.defaultPrevented = true }
		}
		Object.assign(event, data);
		
		let emitter: EventEmitter|undefined = this;
		while (emitter && !event.defaultPrevented) {
			emitter.listeners[eventName]?.forEach(listener => listener(event));
			emitter = emitter.parent;
		}
	}

}