
/**
 * Factory method as entry point to API
 */
export { newAgent } from "./Agent";
export { version, date } from "./Release";

/**
 * As name of modules changed from "AnCti" to "anCti"
 * we create an alias for backwards compatibility.
 */
if (typeof window !== "undefined") {
    (window as any).AnCti = (window as any).anCti ;
} 

