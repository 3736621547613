
import { Agent } from './Agent';
import { Device } from './Device';
import { EventEmitter } from "./EventEmitter";
import * as anCti from "./ancti";

export class Call implements anCti.Call {

	readonly agent: Agent;
 	readonly device: Device;

    callID: string;
    number: string;
	name: string;
    remoteStream: MediaStream;
    localConnectionInfo: anCti.LocalConnectionState;
    lastRedirection: any;
        
    pc?: RTCPeerConnection;
	localStream?: MediaStream;
    localTracks = {
        audio: undefined,
        video: undefined,
        display: undefined,
    };
    readonly globallyUniqueCallLinkageID?: string;

    protected eventEmitter: EventEmitter;
    protected localDtmfSender: any;
    
    protected candidatePolicy: string = "none";
    protected candidates: any[] = [];

    protected fetchConnectedNumber: boolean = false;

    // https://w3c.github.io/webrtc-pc/#perfect-negotiation-example
    protected negotiationNeeded: boolean = false;
    protected makingOffer: boolean = false;
    protected ignoreOffer: boolean = false;
    protected isSettingRemoteAnswerPending: boolean = false;
    protected polite: boolean = false;

    // non-standard api, just used for legacy implementations as on android
    protected androidWebRTC: boolean = false;

    constructor(agent: Agent, device: Device,callID: string) {
      	this.agent = agent;
        this.device = device;
        this.callID = callID;
        this.eventEmitter = new EventEmitter(device.eventEmitter);
    }

     // internal, used by agent
    processEvent(name: string, content: any) {
        switch (name) {
            case 'OriginatedEvent':
                this.fetchConnectedNumber = true;
                this.agent.parseDeviceID(content.calledDevice,this);
                break;
            case 'DeliveredEvent':
                if (content?.localConnectionInfo=="alerting") {
					this.agent.parseDeviceID(content.callingDevice, this);
				} else {
                	this.agent.parseDeviceID(content.calledDevice, this);
				    this.fetchConnectedNumber = true;
                }
                break;
            case 'EstablishedEvent':
                if (this.fetchConnectedNumber) {
                    this.agent.parseDeviceID(content.answeringDevice, this);
                    this.fetchConnectedNumber = false;
                } else {
                    // fetch remote identity
                    if (content.callingDevice.deviceIdentifier==this.device.deviceID) {
                        this.agent.parseDeviceID(content.answeringDevice, this);
                    } else {         
                        this.agent.parseDeviceID(content.callingDevice, this);
                    }
                }
                break;
            case 'TransferedEvent': // ecma style ...
            case 'TransferredEvent': {
                // check if callID changed
                if (content.transferredConnections) {
                    for (let e of content.transferredConnections) {
                        if (e.connectionListItem.newConnection?.callID && e.connectionListItem.oldConnection) {
                            this.callID = e.connectionListItem.newConnection.callID;
                            break;
                        }
                    }
                }

                this.agent.parseDeviceID(content.transferredToDevice,this);
                break;
            }
            case 'ConferencedEvent': {
                // check if callID changed
                let peers = [];
                for (let e of content.conferenceConnections) {
                    let peer = this.agent.parseDeviceID(e.connectionListItem.newConnection.deviceID);
                    peers.push(peer.number);
                  
                    if (peers.length==1) {
                        // on AS the first element is always the own connection
                        let newCallID = e.connectionListItem.newConnection.callID; 
                        if (newCallID!=this.callID) {
                            this.agent.debug(`conferenced callID changes from ${this.callID} to ${newCallID}`);
                            this.callID = newCallID;
                        }
                    }
                }

                // use all numbers of peers
                this.number = peers.join("/");
                break;
            }
        }

        Object.assign(this,content);

        if (this.localConnectionInfo=="null" || this.localConnectionInfo=="fail") {
            this.deletePeerConnection();
            this.device.removeCall(this.callID);
        }
        if (content.lastRedirectionDevice?.numberDialed) {
            this.lastRedirection = this.agent.parseDeviceID(content.lastRedirectionDevice.numberDialed);
        }
        this.notifyEvent(name,content);
    }

    shutdown() {
        // simulate clear-event from server
        this.agent.debug("force shutdown of call",this.callID);
        this.processEvent('ConnectionClearedEvent',{
            cause: "shutdown",
            droppedConnection: {
                callID: this.callID,
                deviceID: this.device.deviceID
            },
            localConnectionInfo: "null",
        });
    }

    notifyEvent(name:string,content:any) {
        this.eventEmitter.notify("call", {
            call: this, 
            name: name, 
            device: this.device,
            content: content
        });
    }

    processSnapshotDevice(snapshotDeviceResponseInfo: any) {
        const cstate = snapshotDeviceResponseInfo?.localCallState?.compoundCallState?.localConnectionState;
        if (cstate && !this.localConnectionInfo) {
            this.agent.debug("creating call snapshot call in state",cstate)
            this.localConnectionInfo = cstate;
            this.notifyEvent("SnapshotDeviceResponse",snapshotDeviceResponseInfo);
        }
    }

    processSnapshotCall(snapshotData: any) {
        snapshotData.forEach((entry:any) => {
            if (entry.snapshotCallResponseInfo?.deviceOnCall?.deviceIdentifier==this.device.deviceID) {
                if (entry.snapshotCallResponseInfo?.localConnectionInfo) {
                    this.localConnectionInfo = entry.snapshotCallResponseInfo?.localConnectionInfo;
                }
            } else {
                this.agent.parseDeviceID(entry.snapshotCallResponseInfo.deviceOnCall.deviceIdentifier,this);
            }
        });
        this.notifyEvent("SnapshotCallResponse",snapshotData);
    }

    async answerCall(options?: anCti.AnswerCallOptions) {
        this.polite = true;
   
        let msg = {
            AnswerCall: {
                callToBeAnswered:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                constraints: options,
            }
        }
         
        return this.agent.invoke(msg);
    }
    
   	async holdCall() {
        return this.agent.invoke({
            HoldCall:{
                callToBeHeld:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                }
            }
        });
    }
    
    async retrieveCall() {
        return this.agent.invoke({ 
            RetrieveCall: {
                callToBeRetrieved:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                }
            }
        });
    }
    
    async updateCall(options: anCti.UpdateCallOptions) {
        let msg:any = {
        	UpdateCall: {
                callToUpdate: {
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                correlatorData: options?.correlatorData,
            }
        }

        if (options?.audio || options?.video || options?.display) {
			msg.UpdateCall.constraints = {
				audio: options?.audio,
				video: options?.video,
                display: options?.display,
			};
		}

        return this.agent.invoke(msg);
    }

	async generateDigits(digits: string,options?: anCti.GenerateDigitsOptions) {
        let msg: any = {
            GenerateDigits: {
				connectionToSendDigits:{
					callID: this.callID,
					deviceID: this.device.deviceID,
				},
    			charactersToSend: digits,
			}
        }

        if (options?.toneDuration) {
            msg.GenerateDigits.toneDuration = options.toneDuration;
        }

        return this.agent.invoke(msg);
	}

    async clearConnection() {
        return this.agent.invoke({ 
            ClearConnection: {
                connectionToBeCleared:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                }
            }
        });
    }

    async singleStepTransferCall(dest: string) {
        return this.agent.invoke({ 
            SingleStepTransferCall:{
                activeCall:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                transferredTo: dest,
            }
        });
    }

    async deflectCall(dest: string) {
        return this.agent.invoke({ 
            DeflectCall:{
                callToBeDiverted:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                newDestination: {
                    device: dest,
                }
            }
        });
    }

    async directedPickupCall(dest: string) {
       return this.agent.invoke({
            DirectedPickupCall:{
                callToBePickedUp:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                requestingDevice: dest,
            }
        });
    }

    async transferCall(otherCall: Call): Promise<anCti.TransferCallResponse> {
        return this.agent.invoke({
            TransferCall:{
                activeCall:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                heldCall: {
                    callID: otherCall.callID,
                    deviceID: otherCall.device.deviceID,
                },
            }
        });
    }

    async conferenceCall(otherCall: Call): Promise<anCti.ConferenceCallResponse> {
        return this.agent.invoke({
            ConferenceCall:{
                activeCall:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                heldCall: {
                    callID: otherCall.callID,
                    deviceID: otherCall.device.deviceID,
                },
            }
        });
    }

    async playMessage(messageToBePlayed: string) {
        return this.agent.invoke({
            PlayMessage:{
                overConnection:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                messageToBePlayed: messageToBePlayed,
            }
        });
    }

    async stop(messageToBeStopped: string) {
        return this.agent.invoke({
            Stop:{
                connection:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                messageToBeStopped: messageToBeStopped,
            }
        });
    }

    async recordMessage(options?: anCti.RecordMessageOptions) {
        return this.agent.invoke({
            RecordMessage:{
                callToBeRecorded:{
                    callID: this.callID,
                    deviceID: this.device.deviceID,
                },
                messageID: options?.messageID,
            }
        });
    }

    protected dispose() {
        this.agent.debug("dispose call "+this.callID);
    }
    
    protected setRemoteStream(stream: MediaStream,track?: MediaStreamTrack) {
        this.remoteStream = stream;

        // setup event-listener to update remote-stream if track ends
        if (stream) {
            stream.onremovetrack = (e:any) => {
                this.agent.debug(this.callID,"remote-track removed",e);
                this.notifyRemoteStream();
            }
            stream.onaddtrack = (e:any) => {
                this.agent.debug(this.callID,"remote-track added",e);
            }
            // #6065 Screensharing video-stream periodically mutes and unmutes
            // for video-streams it might happen that they are reported muted
            // while the stream is still active. -> ignore muted
            // (Maybe we should also ignore for audio, but for now I just fix what is needed)
            if (track?.kind=="audio") {
                track.onmute = (e:any) => {
                    this.agent.debug(this.callID,"remote-track muted",e);
                    this.notifyRemoteStream(track);
                }
                track.onunmute = (e:any) => {
                    this.agent.debug(this.callID,"remote-track unmuted",e);
                    this.notifyRemoteStream(track);
                }
            }
        }                                                  
        this.notifyRemoteStream(track);
    }

    protected notifyRemoteStream(track?: MediaStreamTrack) {
        //this.agent.debug("notifying remote-track",track,this.remoteStream);
        //this.remoteStream?.getTracks().forEach(t => this.agent.debug("remoteStream tracks:",t));
        this.eventEmitter.notify('remotestream',{call: this, pc: this.pc, stream: this.remoteStream, track: track});
    }

    protected patchConstraints(media:string,value:any) {
        if (!value || value==='inactive' || value==='recvonly') {
            return false;
        }
        // enable stream
        let deviceId = this.agent.getMediaDeviceId(media);
        if (deviceId) {
            return { deviceId };
        }
        
        return true;
    }

    protected getTransceiver(kind: string): RTCRtpTransceiver|undefined {
        if (this.androidWebRTC) {
            return undefined;
        }
        let transceiver = this.pc?.getTransceivers().find(t => t?.sender?.track?.kind==kind);
        if (!transceiver) {
            // if there is no sender-track yet, let's try the receiver
            transceiver = this.pc?.getTransceivers().find(t => t.receiver?.track?.kind==kind);
        }
        return transceiver;
    }

    // internal, used by agent
    processGenerateDigits(tones:string,duration:number,interToneGap?:number) {
        this.agent.debug("generating digits",tones,duration);
        if (!this.localDtmfSender && this.localTracks.audio) {
            const audioTransceiver = this.getTransceiver("audio");
            this.localDtmfSender = audioTransceiver?.sender?.dtmf;
            if (!this.localDtmfSender) {
                this.agent.debug("creating new DTMF sender");
                // fallback to deprecated createDTMFSender
                this.localDtmfSender = (<any>this.pc).createDTMFSEnder(this.localTracks.audio);
            }
        }
        if (this.localDtmfSender) {
            this.localDtmfSender.ontonechange = (event:any) => this.agent.debug("ontonechange",event);
            this.localDtmfSender.insertDTMF(tones, duration, interToneGap);
        } else {
            this.agent.debug("no DTMF support!");
        }
    }

    // internal, used by agent
    async processRtcEvent(content:any) {
		try {
            let remoteDescription = content?.remoteDescription;
                
            if (remoteDescription?.type=="close") {
                this.deletePeerConnection();
                return;
            }

            if (!this.pc) {
                if (content?.candidatePolicy) {
                    this.candidatePolicy = content.candidatePolicy;
                }
                this.pc = this.createPeerConnection(content?.configuration);
            }

            // first ensure we have the desired streams and tracks
            if (content?.constraints) {
                let userMedia: any = {};
                let newTracks: any = {};
                                  
                for (let media in content.constraints) {
                    if (content.constraints[media]) {
                        // hide proprietary direction and adds configured deviceId
                        userMedia[media] = this.patchConstraints(media,content.constraints[media]);
                    }
                }

                // first create new tracks
                if (userMedia.audio) {
                    try {
                        const stream = await this.agent.getUserMedia(this,{ audio: userMedia.audio });
                        this.localStream = this.localStream || stream;
                        newTracks.audio = stream.getAudioTracks()[0];
                        this.agent.debug("new audio track",newTracks.audio);
                    } catch (err) {
                        this.agent.info("could not get audio-track",err);
                        this.agent.notify("getmediaerror",{ call: this, kind: "audio", error: err });
                    }
                }

                if (userMedia.display) {
                    // as MC for now just supports one video-stream we skip video
                    userMedia.video = undefined;

                    try {
                        // for now no external constraints are supported
                        const stream = await this.agent.getDisplayMedia(this);
                        this.localStream = this.localStream || stream;
                        newTracks.display = stream.getVideoTracks()[0];
                        this.agent.debug("new display track",newTracks.display);
                    } catch (err) {
                        this.agent.info("could not get display-media",err)    
                        this.agent.notify("getmediaerror",{ call: this, kind: "display", error: err });
                    }
                }

                if (userMedia.video) {
                    try {
                      const stream = await this.agent.getUserMedia(this, { video: userMedia.video } );
                      this.localStream = this.localStream || stream;
                      newTracks.video = stream.getVideoTracks()[0];
                      this.agent.debug("new video track",newTracks.video);
                    } catch (err) {
                      this.agent.info("could not get video-track",err)    
                      this.agent.notify("getmediaerror",{ call: this, kind: "video", error: err });
                    }
                  }

                for (let media in this.localTracks) {

                    let newTrack = newTracks[media];
                    let oldTrack = this.localTracks[media];
                    let transceiver = this.getTransceiver(media);
                    let constraints = content.constraints[media];

                    this.localTracks[media] = newTrack;

                    if (media=='display') {
                        // display track is treated together with video
                        continue;
                    } else if (media=='video') {
                        if (!newTrack && newTracks.display) {
                            // use display-track instead
                            newTrack = newTracks.display;
                            constraints = content.constraints.display;
                        }
                        oldTrack = oldTrack || this.localTracks.display;
                    }
                    
                    if (oldTrack) {
                        this.agent.debug(this.callID,"removing track ",media,oldTrack,transceiver);

                        this.localStream.removeTrack(oldTrack);

                        if (transceiver?.sender) {
                            if (!this.androidWebRTC) {
                                transceiver.sender.replaceTrack(newTrack);
                            }
                            this.pc.removeTrack(transceiver.sender);
                        }
                        
                        oldTrack.stop();

                        if (!newTrack && constraints=="inactive") {
                            // no new track will be added, ensure we inactivate
                            // the receiver to not get the remote stream either
                            if (transceiver) {
                                transceiver.direction = constraints;
                            }
                        }
                    }

                    if (newTrack) {
                        this.agent.debug(this.callID,"adding track ",media,newTrack);
                        this.localStream.addTrack(newTrack);
                        if (this.androidWebRTC) {
                            // android/browser does not support addTrack, fallback to deprecated addStream.
                            (<any>this.pc).addStream(this.localStream);
                        } else if (transceiver) {
                            // other transceiver of same kind found
                            this.agent.debug(this.callID,"replacing track",media);
                            transceiver.sender.replaceTrack(newTrack);
                        } else {
                            this.agent.debug(this.callID,"adding track",media);
                            this.pc.addTrack(newTrack, this.localStream);
                        }

                        // apply sendrecv, sendonly, recvonly, inactive...
                        if (constraints===true) {
                            constraints = "sendrecv";
                        }
                        switch (constraints) {
                            case "sendrecv":
                            case "sendonly":
                            case "recvonly":
                            case "inactive": {
                                if (!transceiver) {
                                    transceiver = this.getTransceiver(media);
                                }
                                if (transceiver) {
                                    transceiver.direction = constraints;
                                }
                                break;
                            }
                        }

                        // Avoid sending audio if we're holding: this would be recorded!
                        // (Later if we support sending local music-on-hold this might change...)
                        if (media=="audio") {
                            // Just enable track on "recvonly" and "sendrecv";
                            // disable on "inactive" and "sendonly"
                            newTrack.enabled = constraints.includes("recv");
                        }

                    } else {
                        // we're not sending, but maybe we would like to receive the media.
                        // ensure the offer contains the configured direction
                        switch (constraints) {
                            case "recvonly":
                            case "inactive": {
                                if (!transceiver) {
                                    transceiver = this.getTransceiver(media);
                                }
                                if (transceiver) {
                                    transceiver.direction = constraints;
                                }
                                break;
                            }
                        }
                    }
                }
                this.negotiationNeeded = true;
            }

            if (remoteDescription) {
                // An offer may come in while we are busy processing SRD(answer).
                // In this case, we will be in "stable" by the time the offer is processed
                // so it is safe to chain it on our Operations Chain now.
                const readyForOffer = !this.makingOffer && (this.pc.signalingState == "stable" || this.isSettingRemoteAnswerPending);
                const offerCollision = remoteDescription.type == "offer" && !readyForOffer;
        
                this.ignoreOffer = !this.polite && offerCollision;
                if (this.ignoreOffer) {
                    return;
                }
            
                // process the received remote-sdp
                this.isSettingRemoteAnswerPending = remoteDescription.type == "answer";
                await this.pc.setRemoteDescription(remoteDescription); // SRD rolls back as needed
                this.isSettingRemoteAnswerPending = false;

                // if we received an offer
                if (remoteDescription.type == "offer") {
                    await this.pc.setLocalDescription();
                    this.negotiationNeeded = true;
                } else if (remoteDescription.type == "answer") {
                    // if we toggle between two calls we have to ensure that
                    // the listeners know that the remote-stream might have changed
                    this.notifyRemoteStream();
                }

            } else if (this.negotiationNeeded) {
                // no remote-sdp received, so we shall create an offer
                this.makingOffer = true;
                let offer = await this.pc.createOffer();
                await this.pc.setLocalDescription(offer);
                this.negotiationNeeded = true;
            }

            if (this.negotiationNeeded) {
                this.sendLocalDescription();
            }
          
            // after processing the message we inform the clients
            this.eventEmitter.notify('localstream',{call: this, stream: this.localStream});
	    } catch(err) {
            this.makingOffer = false;
            this.agent.error("could not process event",err)
        }
    }

    protected createPeerConnection(configuration: any) {
        let pc = new this.agent.RTCPeerConnection(configuration);
        
        // setup all event-listeners
        pc.addEventListener('track',(event:any) => {
            this.agent.debug(this.callID,"track",event);
            const track = event.track;
            
            let [remoteStream] = event.streams;
            if (!remoteStream) {
                // unbelivable: start call with audio+video and then getting "pranswer"
                // => the track has no stream!!!
                // let's manually create a stream to hear early-media
                remoteStream = new MediaStream();
                remoteStream.addTrack(track);
                this.agent.info(`created new stream for ${track.type} track`,remoteStream);
            }

            event.track.addEventListener('unmute', (event2:any) => {
                this.agent.debug(this.callID+": unmute",event2);
                //Redmine #6805: fix for the flickering during screen share
                let handleUnmute = true;
                if (this.remoteStream) {
                    let knownTrack = this.remoteStream.getTrackById(track.id);
                    if (knownTrack && knownTrack.enabled == track.enabled && knownTrack.muted == track.muted) {
                        this.agent.debug("Ignoring event for "+this.callID);
                        handleUnmute = false;
                    }
                }
                if (handleUnmute) {
                    this.setRemoteStream(remoteStream,track);
                }
            });
        });

        pc.addEventListener('negotiationneeded',async (_event:any) => {
            //:this.agent.debug(this.callID,"negotiationneeded",event);
            // event is sometimes fired too late -> set flag manually
            //:this.negotiationNeeded = true;
        });

        pc.addEventListener('icecandidate', ({candidate}) => {
            if (candidate?.candidate) {
                //:this.agent.debug(this.callID+": icecandidate:",candidate.candidate);
                this.candidates.push(candidate.candidate);
            }
        });

        pc.addEventListener('icegatheringstatechange',(_event:any) => {
            //:this.agent.debug(this.callID,"icegatheringstatechange:",pc.iceGatheringState);
            if (pc.iceGatheringState=="complete" && this.candidatePolicy!="none") {
                this.sendLocalDescription(this.candidates);
            }
        });
 
        if (this.androidWebRTC) {
            pc.addEventListener('addstream',({stream}) => {
                this.agent.debug(this.callID,"addstream",stream);
                // android seems not to support track-events -> use this instead
                this.setRemoteStream(stream);
            });

            pc.addEventListener('removestream',(_event:any) => {
                // android seems not to support track-events -> use this instead
                this.setRemoteStream(undefined);
            });
        }

        pc.addEventListener('datachannel',(_event:any) => {
            //:this.agent.debug(this.callID,"datachannel",event);
        });

        pc.addEventListener('connectionstatechange',(_event:any) => {
            //:this.agent.debug(this.callID,"connectionstatechange",event);
        });

        pc.addEventListener('signalingstatechange',(_event:any) => {
            //:this.agent.debug(this.callID,"signalingstatechange",event);
        });

        return pc;
    }

    protected deletePeerConnection() {
        if (this.pc) {
            // android does not have pc.getSenders
            if (this.pc.getSenders) {
                this.pc.getSenders().forEach((sender:any) => this.pc.removeTrack(sender));

                // android fails if we call stop -> do it here
                for (let media in this.localTracks) {
                    this.localTracks[media]?.stop();
                }
            }
            this.pc.close();
            for (let media in this.localTracks) {
                this.localTracks[media] = undefined;
            }
            this.pc = undefined;
            this.localStream = undefined;
            this.setRemoteStream(undefined);
            this.eventEmitter.notify('localstream',{call: this, stream: this.localStream});
        }
    }

    protected sendLocalDescription(candidates:any = undefined) {
        // pc.localDescription is not serializable! create own instance:
        const sdp = { 
            "type": this.pc.localDescription?.type, 
            "sdp": this.pc.localDescription?.sdp
        };
        this.negotiationNeeded = false;
        this.agent.send({
            RtcMessage: {
                connection: {
                    callID: this.callID,
                    deviceID: this.device.deviceID
                },
                localDescription: sdp,
                candidates: candidates
            }});
        this.makingOffer = false;
    }

    protected debugTracks(text: string) {
        this.agent.debug(text);   
        this.pc?.getTransceivers().forEach(t => {
            this.agent.debug("  media",t.sender?.track?.kind || t.receiver?.track?.kind);
            this.agent.debug("    sender   ",t.sender.track);
            this.agent.debug("    receiver ",t.receiver.track);
        });
    }

}
