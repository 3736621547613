
import { Agent } from './Agent';
import { Call } from "./Call";
import { EventEmitter } from "./EventEmitter";
import * as anCti from "./ancti";

export class Device implements anCti.Device {

	agent: Agent
	deviceID: string;
	calls: Call[] = [];
	eventEmitter: EventEmitter;
	presenceState: any;
	newMessages: number = 0;
	oldMessages: number = 0;
	doNotDisturbOn?: boolean;
	avatar?: string;
	namedPresenceState?: string;

	protected monitorCrossRefID?: string;
	protected config: any = {};

	constructor(agent: Agent, deviceID: string) {
		this.agent = agent;
		this.deviceID = deviceID;
		this.agent.parseDeviceID(deviceID, this);
		this.eventEmitter = new EventEmitter(agent["eventEmitter"]);
	}

	getCall(callID: string, create?: boolean): Call | undefined {
		let call = this.calls.find(c => c.callID == callID);
		if (!call && create) {
			call = new Call(this.agent, this, callID);
			this.calls.push(call);
		}
		return call;
	}

	getCalls(): Call[] {
		return this.calls;
	}

	removeCall(callID: string): Call | undefined {
		let call: Call | undefined = undefined;
		this.calls = this.calls.filter(c => {
			if (c.callID != callID) {
				return true;
			} else {
				call = c;
				return false;
			}
		});
		return call
	}

	notify(eventName: string, event: any = {}) {
		this.agent.debug("notify", eventName);
		this.eventEmitter.notify(eventName, event);
	}

	public on(eventName: string, fn: (event: any) => void) {
		this.eventEmitter.on(eventName, fn)
	}

	async monitorStart(options?: any) {
		let msg = {
			MonitorStart: {
				monitorObject: {
					deviceObject: this.deviceID
				}
			}
		};
		Object.assign(msg.MonitorStart, options);
		let response: any = await this.agent.invoke(msg);
		this.monitorCrossRefID = response.monitorCrossRefID;
		Object.assign(this.config, response.config);
		if (this.monitorCrossRefID) {
			this.agent["monitors"][this.monitorCrossRefID] = this;
		}
		return response;
	}

	async monitorStop() {
		let response: any = await this.agent.invoke({
			MonitorStop: {
				monitorCrossRefID: this.monitorCrossRefID
			}
		});
		this.monitorCrossRefID = undefined;
		return response;
	}

	async makeCall(dest: string | Call, options?: anCti.MakeCallOptions): Promise<anCti.MakeCallResponse> {
		if (options?.autoOriginate === true) {
			options.autoOriginate = "doNotPrompt";
		}

		let msg: any = {
			MakeCall: {
				callingDevice: this.deviceID,
				autoOriginate: options?.autoOriginate,
				subjectOfCall: options?.subjectOfCall,
				correlatorData: options?.correlatorData,
			}
		}

		if (options?.audio || options?.video) {
			msg.MakeCall.constraints = {
				audio: options?.audio,
				video: options?.video,
			};
		}

		if (typeof dest === "string") {
			msg.MakeCall.calledDirectoryNumber = dest;
		} else {
			msg.MakeCall.destinationCall = {
				callID: dest.callID,
				deviceID: dest.device?.deviceID
			};
		}

		if (options?.callingNumber) {
			msg.MakeCall.privateData = {
				callingNumber: options.callingNumber,
			};
		}

		return this.agent.invoke(msg);
	}

	async directedPickupCall(call: Call): Promise<anCti.DirectedPickupCallResponse> {
		return this.agent.invoke({
			DirectedPickupCall: {
				callToBePickedUp: {
					callID: call.callID,
					deviceID: call.device.deviceID
				},
				requestingDevice: this.deviceID
			}
		});
	}

	async groupPickupCall(pickGroup: Device): Promise<anCti.GroupPickupCallResponse> {
		return this.agent.invoke({
			GroupPickupCall: {
				pickGroup: pickGroup.deviceID,
				newDestination: this.deviceID,
			}
		});
	}

	async setForwarding(forwardingType: "forwardImmediate" | "forwardBusy" | "forwardNoAns", activateForward: boolean, forwardDN?: string, ringDuration?: number): Promise<anCti.SetForwardingResponse> {
		let msg: any = {
			SetForwarding: {
				device: this.deviceID,
				forwardingType: forwardingType,
				activateForward: activateForward,
				forwardDN: forwardDN
			}
		}
		if (ringDuration) {
			msg.SetForwarding.ringDuration = ringDuration;
		}
		return this.agent.invoke(msg);
	}

	async getForwarding(): Promise<anCti.GetForwardingResponse> {
		return this.agent.invoke({
			GetForwarding: {
				device: this.deviceID
			}
		});
	}

	async setDoNotDisturb(doNotDisturbOn: boolean): Promise<anCti.SetDoNotDisturbResponse> {
		return this.agent.invoke({
			SetDoNotDisturb: {
				device: this.deviceID,
				doNotDisturbOn: doNotDisturbOn
			}
		});
	}

	async getDoNotDisturb(): Promise<anCti.GetDoNotDisturbResponse> {
		let response: anCti.GetDoNotDisturbResponse = await this.agent.invoke({
			GetDoNotDisturb: {
				device: this.deviceID
			}
		});
		this.doNotDisturbOn = response.doNotDisturbOn;
		return response;
	}

	async snapshotDevice(): Promise<anCti.SnapshotDeviceResponse> {
		let response: any = await this.agent.invoke({
			SnapshotDevice: {
				snapshotObject: this.deviceID
			}
		});
		if (response.crossRefIDorSnapshotData?.snapshotData?.length) {
			response.crossRefIDorSnapshotData.snapshotData.forEach((entry: any) => {
				const callID = entry.snapshotDeviceResponseInfo?.connectionIdentifier?.callID;
				if (callID) {
					let call = this.getCall(callID, true);
					call?.processSnapshotDevice(entry.snapshotDeviceResponseInfo);
				}
			});
		}
		return response;
	}

	async snapshotCall(callID: string) {
		let response: any = await this.agent.invoke({
			SnapshotCall: {
				snapshotObject: {
					deviceID: this.deviceID,
					callID: callID
				}
			}
		});
		if (response.crossRefIDorSnapshotData?.snapshotData?.length) {
			const call = this.getCall(callID, true);
			call?.processSnapshotCall(response.crossRefIDorSnapshotData.snapshotData);
			response.call = call;
		}
		return response;
	}

	async joinCall(activeCall: Call, options: anCti.JoinCallOptions = {}) {
		if (options.autoOriginate === true) {
			options.autoOriginate = "doNotPrompt";
		}
		if (!options.participationType) {
			options.participationType = "active";
		}

		let msg: any = {
			JoinCall: {
				activeCall: {
					callID: activeCall.callID,
					deviceID: activeCall.device.deviceID
				},
				joiningDevice: this.deviceID,
				autoOriginate: options?.autoOriginate,
				participationType: options.participationType,
			}
		}

		if (options?.audio || options?.video) {
			msg.JoinCall.constraints = {
				audio: options?.audio,
				video: options?.video,
			};
		}

		if (options?.callingNumber) {
			msg.JoinCall.privateData = {
				callingNumber: options.callingNumber,
			};
		}

		return this.agent.invoke(msg);
	}

	async getPresenceState(): Promise<anCti.GetPresenceStateResponse> {
		let response: any = await this.agent.invoke({
			GetPresenceState: {
				device: this.deviceID,
			}
		});

		this.updatePresenceState(response);
		return response;
	}

	async setPresenceState(options: anCti.SetPresenceStateOptions): Promise<anCti.SetPresenceStateResponse> {
		let msg: any = {
			SetPresenceState: {
				device: this.deviceID,
			}
		}

		if (options?.requestedPresenceState) {
			msg.SetPresenceState.requestedPresenceState = options.requestedPresenceState;
		}

		if (options?.namedPresenceState) {
			msg.SetPresenceState.namedPresenceState = options.namedPresenceState;
		}

		// wait for response and extract the current state
		const response = await this.agent.invoke(msg);
		this.updatePresenceState(response);
		return response;
	}

	async readChatGroups(): Promise<anCti.ChatGroup[]> {
		const response = await this.agent.invoke({
			ReadChatGroups: {
				device: this.deviceID,
			}
		});
		return response.chatGroups;
	}

	newChatGroup(options: anCti.NewChatGroupOptions): Promise<anCti.NewChatGroupResponse> {
		return this.agent.invoke({
			NewChatGroup: {
				device: this.deviceID,
				...options,
			}
		});
	}

	editChatGroup(options: anCti.EditChatGroupOptions): Promise<void> {
		return this.agent.invoke({
			EditChatGroup: {
				device: this.deviceID,
				...options,
			}
		});
	}

	deleteChatGroup(options: anCti.DeleteChatGroupOptions): Promise<void> {
		return this.agent.invoke({
			DeleteChatGroup: {
				device: this.deviceID,
				...options,
			}
		});
	}

	newChatMember(options: anCti.NewChatMemberOptions): Promise<void> {
		return this.agent.invoke({
			NewChatMember: {
				device: this.deviceID,
				...options,
			}
		});
	}

	deleteChatMember(options: anCti.DeleteChatMemberOptions): Promise<void> {
		return this.agent.invoke({
			DeleteChatMember: {
				device: this.deviceID,
				...options,
			}
		});
	}

	newActivity(options: anCti.NewActivityOptions): Promise<void> {
		return this.agent.invoke({
			NewActivity: {
				device: this.deviceID,
				...options,
			}
		});
	}

	activityRead(options: anCti.activityReadOptions): Promise<void> {
		return this.agent.invoke({
			ActivityRead: {
				device: this.deviceID,
				...options,
			}
		});
	}

	uploadRequest(options: anCti.UploadRequestOptions): Promise<anCti.UploadRequestResponse> {
		return this.agent.invoke({
			UploadRequest: {
				device: this.deviceID,
				...options,
			}
		});
	}

	setupCustomFile(name: string): Promise<anCti.SetupFileResponse> {
		return this.agent.invoke({
			SetupCustomFile: {
				device: this.deviceID,
				name,
			}
		});
	}

	deleteCustomFile(name: string): Promise<void> {
		return this.agent.invoke({
			DeleteCustomFile: {
				device: this.deviceID,
				name,
			}
		});
	}

	setupAvatar(options?: any): Promise<anCti.SetupFileResponse> {
		return this.agent.invoke({
			SetupAvatar: {
				device: this.deviceID,
				...options
			}
		});
	}

	deleteAvatar(options?: any) {
		return this.agent.send({
			DeleteAvatar: {
				device: this.deviceID,
				...options
			}
		});
	}

	private updatePresenceState(content: any) {
		if (content.hasOwnProperty("presenceState")) {
			this.presenceState = content.presenceState;
		}
		if (content.hasOwnProperty("avatar")) {
			this.avatar = content.avatar;
		}
		if (content.hasOwnProperty("namedPresenceState")) {
			this.namedPresenceState = content.namedPresenceState;
		}
	}

	async readDirectories(options: any): Promise<any> {
		let response: any = await this.agent.invoke({
			ReadDirectories: {
				scope: this.deviceID,
				text: options.text,
				limit: options.limit,
				match: options.match,
				avatars: options.avatars,
				deviceIds: options.deviceIds,
			}
		});
		return response.entries;
	}

	async readCallDetails(options: any): Promise<any> {
		let response: any = await this.agent.invoke({
			ReadCallDetails: {
				device: this.deviceID,
				since: options?.since,
				limit: options?.limit,
				offset: options?.offset,
				avatars: options.avatars,
			}
		});
		return response.entries;
	}

	async readVoicemail(options: any): Promise<any> {
		let response: any = await this.agent.invoke({
			ReadVoicemails: {
				device: this.deviceID,
				since: options?.since,
				limit: options?.limit,
				offset: options?.offset,
				avatars: options.avatars,
			}
		});
		return response.entries;
	}

	sendConfigNotification(data: any) {
		this.agent.send({
			ConfigNotification: {
				device: this.deviceID,
				data,
			}
		});
	}

	deleteVoicemail(id: string): Promise<any> {
		return this.agent.invoke({
			DeleteVoicemail: {
				device: this.deviceID,
				id,
			}
		});
	}

	updateVoicemail(id: string, options: any): Promise<any> {
		return this.agent.invoke({
			UpdateVoicemail: {
				device: this.deviceID,
				id,
				...options,
			}
		});
	}

	processPresenceState(content: any) {
		this.updatePresenceState(content);

		this.eventEmitter.notify("presencestate", {
			name: "PresenceStateEvent",
			device: this,
			presenceState: content.presenceState,
		});
	}

	processMessageSummary(content: any) {
		this.newMessages = +content.newMessages || 0;
		this.oldMessages = +content.oldMessages || 0;
		this.eventEmitter.notify("messagesummary", {
			name: "MessageSummaryEvent",
			device: this,
			newMessages: this.newMessages,
			oldMessages: this.oldMessages,
		});
	}

	processDoNotDisturb(content: any) {
		this.doNotDisturbOn = content.doNotDisturbOn;
		this.eventEmitter.notify("donotdisturb", {
			name: "DoNotDisturbEvent",
			device: this,
			doNotDisturbOn: content.doNotDisturbOn,
		});
	}

	processForward(content: any) {
		this.eventEmitter.notify("forward", {
			name: "ForwardEvent",
			device: this,
			forwardDN: content.forwardDN,
			forwardStatus: content.forwardStatus,
			forwardingType: content.forwardingType,
		});
	}

	processActivity(content: any) {
		this.eventEmitter.notify("activity", {
			name: "ActivityEvent",
			device: this,
			...content
		});
	}

	processChatGroup(content: any) {
		this.eventEmitter.notify("chatgroup", {
			name: "ChatGroupEvent",
			device: this,
			...content
		});
	}

	processChatGroupDeleted(content: any) {
		this.eventEmitter.notify("chatgroupdeleted", {
			name: "ChatGroupDeletedEvent",
			device: this,
			...content
		});
	}

	processConfigNotification(data: any) {
		this.eventEmitter.notify("configNotification", {
			name: "ConfigNotificationEvent",
			device: this,
			data,
		});
	}

}

